.pl-checkbox input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  background-color: transparent;
  border: 1px solid #9a9898;
  border-radius: 2px;
  cursor: pointer;
}

.pl-checkbox input[type='checkbox']:checked::before {
  content: '✔';
  color: rgb(255, 255, 255);
  font-size: 9px;
  position: absolute;
  left: 2.5px;
  font-weight: 600;
}

.pl-checkbox input[type='checkbox']:checked {
  background-color: #df0d7d;
  position: relative;
  border: none;
}
