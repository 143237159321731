.ais-SearchBox-submitIcon {
  display: none;
}

.ais-SearchBox-resetIcon {
  display: none;
}

.pl-filter input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
}

.pl-filter input[type="checkbox"]:checked::before {
  content: "✔";
  color: rgb(255, 255, 255);
  font-size: 9px;
  position: absolute;
  left: 2.5px;
  font-weight: 600;
}

.pl-filter input[type='checkbox']:checked {
  background-color: #df0d7d;
  position: relative;
  border: none;
}

.filter-search .ais-SearchBox-input {
  width: 100%;
  height: 1.8rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  padding: 0 0.8rem;
  font-size: 0.8rem;
  margin-bottom: 10px;
  background-color: #dcdcdc83;
  color: #434343;
}

.dark .filter-search .ais-SearchBox-input {
  background-color: #62626258;
  color: rgb(212, 212, 212);
}

.filter-search .ais-RefinementList-searchBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

 mark.ais-Highlight-highlighted {
  background-color: #69696923;
  color: #696969;
  padding: 0px 3px;
  border-radius: 5px;
}

.dark mark.ais-Highlight-highlighted {
  background-color: #c8c8c82f;
  color: #c8c8c8;
}