.highlight-text mark {
  background-color: #69696923;
  color: #696969;
  padding: 0px 3px;
  font-weight: 500;
  border-radius: 5px;
}

.dark .highlight-text mark {
  background-color: #c8c8c82f;
  color: #c8c8c8;
  font-weight: 400;
}
